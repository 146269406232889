import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import NuvoImage from "../components/NuvoImage"
import ImageMeta from "../components/ImageMeta"
import { Section, Container } from "../components/Containers"
import { MarkdownViewer } from "../components/Markdown"
import { ImpactSlider } from "../components/Sliders"
import { MapCTA } from "../components/CTA"

const SingleProject = ({ pageContext, data, location }) => {
  const post = data.allProjectsJson.nodes[0]
  const {
    hero,
    heading,
    blurb,
    cards,
    slider,
    mapCta,
    hasImages,
    contentImages
  } = post

  return (
    <Layout>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={post.schemaPage}
        pathname={location.pathname}
      />

      <div className="single-project-page">
        <NuvoImage publicId={hero.image} wrapperClassName="is-hidden-mobile" />
        <NuvoImage
          publicId={hero.imageMobile}
          wrapperClassName="is-hidden-tablet"
        />

        <Section>
          {hasImages ? (
            <Container large>
              <ImageTextContent
                heading={heading}
                blurb={blurb}
                desktopImage={contentImages.image}
                mobileImage={contentImages.imageMobile}
              />
            </Container>
          ) : (
            <Container>
              <h1 className="has-text-centered">{heading}</h1>
              <MarkdownViewer markdown={blurb} />
            </Container>
          )}

          {cards && cards.length > 0 && (
            <div className="grid-even-2 mt-4">
              {cards.map((card, i) => (
                <div key={i} className="mallard-project-card">
                  <h3>{card.heading}</h3>
                  <MarkdownViewer markdown={card.blurb} />
                </div>
              ))}
            </div>
          )}
        </Section>

        <Section colorBack zeroBottom>
          <Container className="mb-4">
            <MarkdownViewer markdown={slider.text} />
          </Container>
          <ImpactSlider slides={slider.slider} />
        </Section>

        <MapCTA
          image={mapCta.image}
          text={mapCta.text}
          button={mapCta.button}
        />
      </div>
    </Layout>
  )
}

const ImageTextContent = ({ heading, blurb, desktopImage, mobileImage }) => {
  return (
    <div className="sp-img-txt">
      <ImageMeta
        cloudName="nuvolum"
        publicId={desktopImage}
        width="auto"
        className="sp-img-txt__desktop-img"
      />

      <div className="sp-img-txt__content">
        <h1>{heading}</h1>
        <MarkdownViewer markdown={blurb} />
      </div>

      <ImageMeta
        cloudName="nuvolum"
        publicId={mobileImage}
        width="auto"
        className="sp-img-txt__mobile-img"
      />
    </div>
  )
}

export const singleProjectPageQuery = graphql`
  query singleProjectQuery($title: String!) {
    allProjectsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          image
          imageMobile
        }
        heading
        blurb
        hasImages
        contentImages {
          image
          imageMobile
        }
        # cards {
        #   heading
        #   blurb
        # }
        slider {
          text
          slider {
            button {
              destination
              appearance
              buttonText
              href
            }
            image
            imageMobile
            heading
          }
        }
        mapCta {
          image
          text
          button {
            buttonText
            appearance
            destination
            href
          }
        }
      }
    }
  }
`

export default SingleProject
