import React from "react"
import classNames from "classnames"
import { MarkdownViewer } from "../Markdown"
import ButtonGroupMap from "../Buttons/ButtonGroupMap"

const CTA = ({ heading, blurb, buttons, colorBack, className }) => {
  let ctaClasses = classNames("section", "cta", className, {
    "color-back": colorBack
  })

  return (
    <div className={ctaClasses}>
      <div className="container has-text-centered">
        <h3>{heading}</h3>
        <MarkdownViewer markdown={blurb} />
        <ButtonGroupMap isCentered buttons={buttons} />
      </div>
    </div>
  )
}

export default CTA
