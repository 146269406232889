import React, { useRef } from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"
import ImageMeta from "../ImageMeta"
import { Container, Section } from "../Containers"
import Button from "../Buttons/Button"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ImpactSlider = () => {
  const sliderRef = useRef(null)

  const SliderArrow = ({ isLeft, onClick }) => {
    const arrowClass = isLeft ? "left-arrow" : "right-arrow"
    const classes = `slick-arw ${arrowClass}`
    const icon = isLeft ? faChevronLeft : faChevronRight

    return (
      <div
        role="button"
        tabIndex={0}
        aria-label={`go to ${isLeft ? "previous" : "next"} carousel slide`}
        className={classes}
        onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
      </div>
    )
  }

  const slides = [
    "The City Planning Dept decided Mallard Pointe doesn’t require an environmental review. What could possibly go wrong?",
    "Mallard Pointe’s developers should have to follow the rules like the rest of us.",
    "Belvedere’s R-2 zoning flatly prohibits apartment buildings. Why don’t the rules apply to Mallard Pointe?",
    "The Mallard Pointe project, with its massive apartment building, will be built on bay mud and fill. Can you say “big-time geotechnical issues”?"
  ]

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <div className="text-slider">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, i) => (
          <div key={i}>
            <Section smallSpacing>
              <Container small>
                <h2
                  role="heading"
                  style={{
                    fontWeight: "bold",
                    textWrap: "balance",
                    color: "#ce0000",
                    textAlign: "center"
                  }}>
                  {slide}
                </h2>
              </Container>
            </Section>
          </div>
        ))}
      </Slider>

      {/* <SliderArrow isLeft onClick={() => sliderRef.current.slickPrev()} />
      <SliderArrow onClick={() => sliderRef.current.slickNext()} /> */}
    </div>
  )
}

export default ImpactSlider
