import React from "react"
import { Section, Container } from "../Containers"
import ImageMeta from "../ImageMeta"
import { MarkdownViewer } from "../Markdown"
import Button from "../Buttons/Button"

const MapCTA = ({ image, button, text }) => {
  return (
    <Section colorBack className="map-cta">
      <Container>
        <div className="map-cta__content grid-even-2">
          <ImageMeta publicId={image} cloudname="nuvolum" width="auto" />
          <div>
            {/* {text && <MarkdownViewer className="mb-3" markdown={text} />} */}
            <Button
              buttonText={button.buttonText}
              appearance={button.appearance}
              internal
              href={button.href}
              className="mx-auto--mobile"
            />
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default MapCTA
