import React, { useRef } from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-light-svg-icons"
import ImageMeta from "../ImageMeta"
import { Container } from "../Containers"
import Button from "../Buttons/Button"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const ImpactSlider = ({ slides }) => {
  const sliderRef = useRef(null)

  const SliderArrow = ({ isLeft, onClick }) => {
    const arrowClass = isLeft ? "left-arrow" : "right-arrow"
    const classes = `slick-arw ${arrowClass}`
    const icon = isLeft ? faChevronLeft : faChevronRight

    return (
      <div
        role="button"
        tabIndex={0}
        aria-label={`go to ${isLeft ? "previous" : "next"} carousel slide`}
        className={classes}
        onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
      </div>
    )
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  return (
    <div className="impact-slider">
      <Slider ref={sliderRef} {...settings}>
        {slides.map((slide, i) => (
          <div key={i} className="impact-slider__slide relative">
            <ImageMeta
              cloudName="nuvolum"
              publicId={slide.image}
              width="auto"
              className="is-hidden-mobile"
            />
            <ImageMeta
              cloudName="nuvolum"
              publicId={slide.imageMobile}
              width="auto"
              className="is-hidden-tablet"
            />

            <div className="impact-slider__content">
              <p role="heading">{slide.heading}</p>
              <Button
                buttonText={slide.button.buttonText}
                href={slide.button.href}
                appearance={slide.button.appearance}
                internal
              />
            </div>
          </div>
        ))}
      </Slider>

      <SliderArrow isLeft onClick={() => sliderRef.current.slickPrev()} />
      <SliderArrow onClick={() => sliderRef.current.slickNext()} />
    </div>
  )
}

export default ImpactSlider
